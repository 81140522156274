import React from "react";
import { FaHtml5, FaCss3Alt, FaJs, FaReact, FaNodeJs } from "react-icons/fa";
import { TbBrandCpp } from "react-icons/tb";
import { SiTailwindcss, SiMicrosoftsqlserver } from "react-icons/si";

function Cunostinte() {
  return (
    <div
      name="cunostinte"
      className="w-full h-screen bg-blue-950 text-gray-300"
    >
      <div className="max-w-[1000px] mx-auto p-4 sm:pt-4 pt-16 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-2 border-orange-500">
            Cunostinte
          </p>
          <p className="py-4">
            Acestea sunt cateva dintre instrumentele pe care le-am folosit de-a
            lungul timpului.
          </p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <FaHtml5 className="mx-auto" size={80} />
            <p className="my-4">HTML</p>
          </div>
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <FaCss3Alt className="mx-auto" size={80} />
            <p className="my-4">CSS</p>
          </div>
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <FaJs className="mx-auto" size={80} />
            <p className="my-4">JavaScript</p>
          </div>
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <FaReact className="mx-auto" size={80} />
            <p className="my-4">React</p>
          </div>
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <SiMicrosoftsqlserver className="mx-auto" size={80} />
            <p className="my-4">SQL Server</p>
          </div>
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <FaNodeJs className="mx-auto" size={80} />
            <p className="my-4">Node JS</p>
          </div>
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <TbBrandCpp className="mx-auto" size={80} />
            <p className="my-4">C++</p>
          </div>
          <div className="shadow-md cursor-default shadow-[#040c16] hover:shadow-white hover:scale-110 duration-300">
            <SiTailwindcss className="mx-auto" size={80} />
            <p className="my-4">Tailwind CSS</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cunostinte;
