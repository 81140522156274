import React from "react";

function DespreMine() {
  return (
    <div
      name="despremine"
      className="w-full h-screen bg-blue-950 text-gray-300 flex flex-col justify-center items-center"
    >
      <div className="max-w-[1240px] mx-auto h-full flex flex-col justify-center items-center p-4 sm:mt-0 mt-10">
        <h1 className="text-4xl font-bold border-b-2 border-orange-500 pb-2">
          Despre mine
        </h1>
        <p className="mt-6 sm:text-xl text-lg">
          &emsp;&emsp;Sunt un programator dedicat imbunatatirii vietii de zi cu
          zi prin intermediul aplicatiilor pe care le creez. Pentru mine, codul
          este un instrument magic care poate transforma idei in realitate si
          poate aduce bucurie si eficienta in rutina noastra. Pe langa
          aplicatii, imi place sa explorez si sa dezvolt site-uri web frumoase
          si functionale. Consider internetul ca pe un mediu in care
          creativitatea si tehnologia se intalnesc si colaboreaza pentru a oferi
          experiente interactive si memorabile. Dar programarea si crearea de
          site-uri nu sunt singurele lucruri care imi definesc pasiunea. Muzica
          este, de asemenea, o parte importanta din viata mea. Imi place sa
          explorez diverse genuri muzicale si sa ma las purtat de ritmurile lor
          captivante. De multe ori, atunci cand ma aflu in fata calculatorului,
          ma insotesc notele muzicale care imi alimenteaza creativitatea si ma
          ajuta sa ma concentrez mai bine.
        </p>
      </div>
    </div>
  );
}

export default DespreMine;
