import Cunostinte from "./components/Cunostinte";
import DespreMine from "./components/DespreMine";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <DespreMine />
      <Cunostinte />
    </div>
  );
}

export default App;
