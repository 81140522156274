import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import { AiOutlineArrowDown } from "react-icons/ai";

function Hero() {
  return (
    <div
      name="acasa"
      className="w-full h-screen flex flex-col justify-center items-center bg-blue-950"
    >
      <h1 className="text-orange-500 sm:text-6xl text-4xl font-bold">
        Salut, eu sunt Darius!
      </h1>
      <p className="text-gray-300 text-2xl sm:text-4xl mt-8">
        Sunt pasionat de{" "}
        <Typed
          strings={["programare.", "muzica.", "animatii."]}
          typeSpeed={60}
          backSpeed={60}
          backDelay={2000}
          showCursor={false}
          loop
        />
      </p>
      <Link
        to="despremine"
        smooth={true}
        duration={500}
        className="mt-8 text-blue-950 bg-orange-500 hover:bg-transparent hover:text-orange-500 duration-300 border-2 border-orange-500 rounded-md p-2 sm:text-xl text-xl cursor-pointer"
      >
        <AiOutlineArrowDown size={25} />
      </Link>
    </div>
  );
}

export default Hero;
