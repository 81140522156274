import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-scroll";

function Navbar() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav((prev) => !prev);
  return (
    <div className="w-full h-[60px] fixed flex justify-between items-center bg-blue-950 px-4">
      <Link
        className="text-orange-500 text-4xl font-bold cursor-pointer"
        to="acasa"
        smooth={true}
        duration={500}
      >
        Darius.
      </Link>
      <ul className="text-gray-300 text-xl font-bold hidden md:flex items-center">
        <li className="mx-1 cursor-pointer hover:bg-orange-500 hover:text-blue-950 p-2 rounded-md duration-500">
          <Link to="acasa" smooth={true} duration={500}>
            Acasa
          </Link>
        </li>
        <li className="mx-1 cursor-pointer hover:bg-orange-500 hover:text-blue-950 p-2 rounded-md duration-500">
          <Link to="despremine" smooth={true} duration={500}>
            Despre mine
          </Link>
        </li>
        <li className="mx-1 cursor-pointer hover:bg-orange-500 hover:text-blue-950 p-2 rounded-md duration-500">
          <Link to="cunostinte" smooth={true} duration={500}>
            Cunostinte
          </Link>
        </li>
        <li className="mx-1 cursor-pointer rounded-md p-2 bg-red-600 text-white duration-500">
          <a href="https://www.youtube.com/@darius_piano/">YouTube</a>
        </li>
      </ul>

      <div className="block md:hidden cursor-pointer z-20">
        {!nav ? (
          <GiHamburgerMenu
            className="text-gray-300"
            onClick={handleClick}
            size={30}
          />
        ) : (
          <AiOutlineClose
            className="text-orange-500"
            onClick={handleClick}
            size={30}
          />
        )}
      </div>

      <div
        className={
          !nav
            ? "hidden"
            : "z-10 w-full h-screen fixed left-0 top-0 flex justify-center items-center bg-blue-950"
        }
      >
        <ul className="text-gray-300 text-4xl font-bold flex flex-col items-center">
          <li className="my-8 cursor-pointer hover:bg-orange-500 hover:text-blue-950 px-6 py-4 rounded-md duration-500">
            <Link onClick={handleClick} to="acasa" smooth={true} duration={500}>
              Acasa
            </Link>
          </li>
          <li className="my-8 cursor-pointer hover:bg-orange-500 hover:text-blue-950 px-6 py-4 rounded-md duration-500">
            <Link
              onClick={handleClick}
              to="despremine"
              smooth={true}
              duration={500}
            >
              Despre mine
            </Link>
          </li>
          <li className="my-8 cursor-pointer hover:bg-orange-500 hover:text-blue-950 px-6 py-4 rounded-md duration-500">
            <Link
              onClick={handleClick}
              to="cunostinte"
              smooth={true}
              duration={500}
            >
              Cunostinte
            </Link>
          </li>
          <li className="my-8 cursor-pointer rounded-md px-6 py-4 bg-red-600 text-white duration-500">
            <a href="https://www.youtube.com/@darius_piano/">YouTube</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
